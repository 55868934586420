<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <h3>Total Data Headline : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col lg="20" md="12" class="mb-50">
          <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-primary" class="mt-2 mt-md-0"
            @click="modalAddheadline()">
            <feather-icon icon="PlusIcon" class="mr-26" />
            <span>Buat Baru</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>

    <!-- table -->
    <b-table ref="selectableTable" selectable :select-mode="selectMode" :items="items" :fields="fields" responsive
      class="mb-0" @row-selected="onRowSelected">
      <template #cell(document)="data">
        <a :href="data.item.document" v-if="data.item.document" target="_blank">Open</a>
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon">
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="modalUpdateheadline(data.item)">
            <feather-icon icon="CheckSquareIcon" size="16" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteDataHeadline(data.item)">
            <feather-icon icon="XSquareIcon" size="16" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination v-model="currentPage" hide-goto-end-buttons :total-rows="rows" :per-page="perPage" />
      </div>
    </b-card-body>
    <b-modal id="modal-export" hide-footer>
      <template #modal-title> Input Email Tujuan </template>
      <div class="d-block text-center">
        <p>
          Data yang akan diexport lebih dari <b>500</b> baris dan akan dikirim
          via email, Silahkan masukkan email anda!
        </p>
      </div>
      <b-form-group>
        <label for="email">Email:</label>
        <b-form-input id="email" type="email" placeholder="Email Address" v-model="email" />
      </b-form-group>
      <b-button class="mt-3" variant="outline-primary" block @click="exportData()">
        <feather-icon icon="DownloadCloudIcon" /> Export
      </b-button>
    </b-modal>

    <b-modal id="modal-add-headline" title="Tambah headline" cancel-variant="outline-secondary" hide-footer>
      <b-form @submit.prevent>
        <b-form-group label="Link" label-for="link ">
          <b-form-input id="link" v-model="linkData" placeholder="link" />
        </b-form-group>
        <b-form-group label="bgcolorhex" label-for="bgcolorhex ">
          <b-form-input id="bgcolorhex" v-model="bgcolorhex" placeholder="bgcolor" />
        </b-form-group>
        <br>
        <b-button variant="primary" type="submit" block :disabled="invalid" @click="saveDataHeadline">
          Tambah headline
        </b-button>

      </b-form>
    </b-modal>

    <!-- edit headline-->
    <b-modal id="modal-update-headline" title="Edit headline" cancel-variant="outline-secondary" hide-footer>
      <b-form @submit.prevent>
        <b-form-input id="id_headline" v-model="updateheadline.id" placeholder="Judul headline" hidden />
        <b-form-group label="Head Link" label-for="gallery_title ">
          <b-form-input id="HeadLink" v-model="updateheadline.headline_link" placeholder="headlink" />
        </b-form-group>
      </b-form>
      <b-button variant="primary" type="submit" block :disabled="invalid" @click="updateDataHeadline">
        Edit headline
      </b-button>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody, BPagination, BFormTextarea, BFormFile,
  BButtonToolbar, BButtonGroup, BDropdown, BDropdownItem, BFormInput, BRow, BCol, BModal
} from 'bootstrap-vue'
import API from '@/api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      rows: 0,
      perPage: 20,
      modes: ['multi', 'single', 'range'],
      fields: [{ key: 'id', label: 'Id' }, 'headline_link', 'created_at', 'updated_at', 'headline_active', 'action'],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        0: 'Register', 1: 'Verify', 2: "Can't find", 3: 'Input wrong data'
      },
      {
        0: 'light-primary', 1: 'light-success', 2: 'light-warning', 3: 'light-warning', 5: 'light-info',
      }],
      selectMode: 'multi',
      selected: [],

      search: null,
      detailPendidikan: {},
      detailheadline: {},
      updateheadline: {},
      base64Data: '',
      option: ['video', 'image']
    }
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getHeadline()
      }
      else {
        this.currentPage = 1
      }

    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name
      }
      return `${files.length} files selected`
    },
    showDetailheadline(item) {
      this.detailheadline = item
      console.log(this.detailheadline)
      this.$bvModal.show("modal-detail")

    },

    modalAddheadline() {
      this.$bvModal.show("modal-add-headline")
    },


    modalUpdateheadline(item) {
      this.updateheadline = item
      this.$bvModal.show("modal-update-headline")
    },
    async getHeadline() {

      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)


        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,

        }
        const { data } = await API.headline.list(form)
        this.data_table = data
        this.items = data.data
        this.rows = data.total

        this.handleMsgSuccess('Berhasil tampilkan data headline')
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },


    async saveDataHeadline() {
      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)

        var bodyHeadline = {
          headline_link: this.linkData,
          headline_bgcolor: this.bgcolorhex,
        }
        await API.headline.create(bodyHeadline)
        this.handleMsgSuccess('Berhasil tambah headline')
        this.refreshPage()
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'form yang anda isi salah')
      } finally {
        this.$bvModal.hide("modal-add-headline")
        this.clearSelected()
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)

      }


    },

    async updateDataHeadline() {
      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)

        var id = this.updateheadline.id
        let body = {
          headline_link: this.updateheadline.headLink,
        }
        await API.headline.update(id, body)
        this.refreshPage()
        this.handleMsgSuccess('Berhasil Update headline')

      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'gagal update headline')
      } finally {
        this.$bvModal.hide("modal-update-headline")
        this.clearSelected()
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },


    async deleteDataHeadline(ids) {
      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)

        let deleteheadline = ids.id

        const { data } = await API.headline.delete(deleteheadline)
        console.log(data)
        this.handleMsgSuccess('Berhasil Delete headline')
        this.refreshPage()
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'gagal delete headline')
      } finally {
        this.clearSelected()
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },



  },
  mounted() {
    this.getHeadline()
  },
  watch: {
    currentPage() {
      this.getHeadline()
    },

  }
}
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
